<template>
  <div v-if="PageScript && pageLayout.length > 0" class="container">
    <div>
      <template v-for="formItem in pageLayout">
        <div v-if="formItem.fmCode !== 'images' && formItem.formItems && formItem.formItems.length" :key="formItem.fmCode" class="form h-shadow-sm">
          <div class="form-title h-flex h-flex-wrap h-row-between">
            <span class="title">{{ formItem.fmName }}</span>
            <div class="h-flex h-font-sm h-primary-color">
              <OcrConvert
                v-if="formItem.fmCode === 'customer' && pageForm.customer && pageForm.customer.certificateType === '1'"
                :config="{ title: '拍摄或上传身份证识别', type: 'idcard', upload: { objCode: 'customer', fieldCode: 'idcardFront' } }"
                @ocrVal="res => scriptObj.setOcrIDCard(res)"
                @uploadVal="res => scriptObj.setUploadVal(res)"
              />
              <OcrConvert
                v-else-if="formItem.fmCode === 'customer' && pageForm.customer && pageForm.customer.certificateType === '6'"
                :config="{ title: '拍摄或上传营业执照识别', type: 'busilicense', upload: { objCode: 'customer', fieldCode: 'busiLicense' } }"
                @ocrVal="res => scriptObj.setOcrBusilicense(res)"
                @uploadVal="res => scriptObj.setUploadVal(res)"
              />
              <OcrConvert
                v-else-if="formItem.fmCode === 'vehicle'"
                :config="{ title: '拍摄或上传行驶证识别', type: 'vehiclelicense', upload: { objCode: 'vehicle', fieldCode: 'vehicleLicense' } }"
                @ocrVal="res => scriptObj.setOcrVehicle(res)"
                @uploadVal="res => scriptObj.setUploadVal(res)"
              />
            </div>
          </div>
          <div class="form-content">
            <HYForm :ref="formItem.fmCode + 'FormRef'" :config="formItem" :form="pageForm[formItem.fmCode]">
              <template v-for="code in authProductCodes" :slot="code + 'Slot'">
                <div :key="code" style="padding-bottom: 10px;">
                  <div class="pd-ck-cls" style="color: #323233;font-size: 16px;padding-top: 10px;">
                    <van-row>
                      <van-col :span="24" style="height: 25px;">
                        <van-col :span="16">
                          <van-checkbox v-model="productFormItem[code].checked" :disabled="productFormItem[code].disabled" shape="square" checked-color="#57ccc2" style="padding-left: 8px;" @click.stop="handleBuyProduct(code)">{{
                            productFormItem[code].config.fmName
                          }}</van-checkbox>
                        </van-col>
                        <van-col v-if="productFormItem[code].checked" :span="8" style="text-align: right;padding-right: 20px;">
                          <span class="h-price-color">￥{{ amountFilter(productFormItem[code].coverageAmount) || 0.0 }}</span>
                        </van-col>
                      </van-col>
                    </van-row>
                  </div>
                  <HYForm :ref="code + 'FormRef'" :config="productFormItem[code].config" :form="productFormItem[code].form" />
                </div>
              </template>
            </HYForm>
          </div>
        </div>
      </template>
      <div v-if="fileLayout" class="form h-shadow-sm">
        <div class="form-title h-flex h-flex-wrap h-row-between">
          <div class="title">影像信息</div>
        </div>
        <template v-for="imgItem in fileLayout">
          <div v-if="imgItem.show" :key="imgItem.prop" class="form">
            <div class="h-flex h-flex-wrap h-col-center">
              <div class="h-font-md h-p-l-5">
                <span style="color:#ee0a24;" v-if="imgItem.required">*</span>
                <span>{{ imgItem.label }}</span>
                <span v-if="imgItem.desc" class="h-font-sm h-text-secondary-color">{{ imgItem.desc }}</span>
              </div>
              <div v-if="imgItem.tip">
                <van-popover v-model="imgItem.tipPopover" placement="top" trigger="click">
                  <div class="h-font-sm h-text-secondary-color h-p-4">{{ imgItem.tip }}</div>
                  <template #reference>
                    <van-icon name="info" size="18" color="#57ccc2" />
                  </template>
                </van-popover>
              </div>
            </div>
            <div class="form-content h-p-5">
              <HUpload
                :key="imgItem.prop"
                :config="imgItem"
                :value="pageForm.images[imgItem.prop]"
                @change="
                  imgs => {
                    handleImagChange(imgItem.prop, imgs);
                  }
                "
              ></HUpload>
            </div>
          </div>
        </template>
      </div>
      <div class="agreement h-p-10">
        <van-checkbox v-model="pageData.isAgreement" checked-color="#ff5228" shape="square" icon-size="16px" style="position: relative;top: 3px;"></van-checkbox>
        <span class="h-font-sm h-price-color h-p-l-8 " @click="protocolCheck">请确认将服务产品内容及条款特别是免责条款逐一向客户清晰准确介绍，并确认各项信息录入系统的真实、准确。</span>
      </div>
      <div class="submitBtn">
        <div class="fixed h-flex h-flex-nowrap">
          <div class="price h-flex-2 h-flex h-col-center h-flex-nowrap">
            <div class="h-flex-1 h-font-xl h-font-bold-sm h-text-center">
              <span class="h-font-md h-p-r-2 h-text-desc-color">销售价格</span>
              <span class="h-price-color">￥{{ amountFilter((pageForm.saleInfo && pageForm.saleInfo.saleAmount) || 0.0) }}</span>
            </div>
          </div>
          <van-button class="h-flex-1" block type="primary" :loading="pageData.affirmLoading" @click="affirm">提交</van-button>
        </div>
      </div>
    </div>

    <van-dialog v-model="pageData.noPassShow" closeOnClickOverlay :showConfirmButton="false">
      <div class="noPass">
        <div class="noPass_close">
          <van-icon name="cross" color="#666666" @click="pageData.noPassShow = false" />
        </div>
        <div style="text-align: center;">
          <van-image :src="require('@/assets/images/fail.png')" />
        </div>
        <div style="text-align: center; font-weight: bold;">购买失败</div>
        <p class="fail-reason">{{ pageData.noPassMsg }}</p>
      </div>
    </van-dialog>

    <van-dialog v-model="pageData.auditShow" :showConfirmButton="false">
      <div class="noPass">
        <div class="noPass_close">
          <van-icon name="cross" color="#666666" @click="toList()" />
        </div>
        <div style="text-align: center;">
          <van-image :src="require('@/assets/images/fail.png')" />
        </div>
        <div style="text-align: center; font-weight: bold;">待人工审核</div>
        <p class="fail-reason">{{ pageData.noPassMsg }}</p>
      </div>
    </van-dialog>

    <van-dialog v-model="pageData.passShow" :showConfirmButton="false">
      <div class="noPass">
        <div class="noPass_close">
          <van-icon name="cross" color="#666666" @click="toList()" />
        </div>
        <div style="text-align: center;">
          <van-image :src="require('@/assets/images/success.png')" />
        </div>
        <div style="text-align: center; font-weight: bold;">下单成功</div>
        <p class="fail-reason">订单审核通过，客户确认中。</p>
      </div>
    </van-dialog>

    <van-popup v-model="pageData.affirmShow" position="bottom" round closeable :style="{ maxHeight: '90%' }">
      <div class="h-p-15 h-font-xl h-font-bold-sm h-p-r-40">{{ calcData.configName }}</div>
      <div class="affirm-content">
        <van-cell-group>
          <van-cell v-if="pageForm.vehicle && pageForm.vehicle.frameNo" title="车架号" :value="pageForm.vehicle.frameNo" />
          <van-cell v-if="pageForm.vehicle && pageForm.vehicle.plateNo" title="车牌号" :value="pageForm.vehicle.plateNo" />
          <van-cell v-if="pageForm.vehicle && pageForm.vehicle.registerDate" title="车辆注册日期" :value="dayjs(pageForm.vehicle.registerDate).format('YYYY-MM-DD')" />
          <van-cell v-if="pageForm.orderExt && pageForm.orderExt.transferTime" title="过户日期" :value="dayjs(pageForm.orderExt.transferTime).format('YYYY-MM-DD')" />
          <van-cell v-if="pageForm.customer && pageForm.customer.phone" title="手机号" :value="this.pageForm.customer.phone" />
          <template v-for="(code, ind) in authProductCodes">
            <van-cell v-if="productFormItem[code].checked" :key="ind" :title="productFormItem[code].config.fmName + '价格'" :value="amountFilter(productFormItem[code].coverageAmount || '0.00')" /> </template
          ><van-cell title="服务起期" :value="calcData.effectiveDay && `${dayjs(calcData.effectiveDay).format('YYYY-MM-DD')} 00时`" />
          <van-cell title="服务止期" :value="calcData.expiryDay && `${dayjs(calcData.expiryDay).format('YYYY-MM-DD')} 24时`" />
        </van-cell-group>
      </div>
      <div class="h-flex">
        <!-- <div class="price h-flex-1 h-text-center">
          <span class="h-price-color h-font-bold-sm">
            {{calcData.customerPrice||0.0}}
          </span>
          <span class="h-font-sm h-p-l-2">元</span>
        </div> -->
        <van-button class="h-flex-1" block type="primary" :loading="pageData.submitLoading" @click="submit">确认</van-button>
      </div>
    </van-popup>
    <FloatBtn v-if="!pageData.submitLoading && pageData.storageFlag" @click="storage" text="暂" />
  </div>
</template>
<script>
import HYForm from "@/components/Form/index.vue";
import HUpload from "@/components/Form/items/HUpload.vue";
import OcrConvert from "../components/OcrConvert";
import layoutData from "@/views/product/layout/orderData";
import scriptLib from "@/views/product/layout/orderScript";
import { request } from "@/api/service";
import { get, merge, values, cloneDeep, keys, sortBy } from "lodash";
import dayjs from "dayjs";
import { mapState } from "vuex";
import store from "@/store";
import ImagesQuicklyCompress from "images-quickly-compress";
import FloatBtn from "@/components/FloatBtn";
import setting from "@/setting";
export default {
  name: "ProductIndex",
  components: { HYForm, HUpload, OcrConvert, FloatBtn },
  provide() {
    return {
      pageContext: this,
      pageForm: this.pageForm,
      pageLayout: this.pageLayout,
      pageLayoutMap: this.pageLayoutMap,
      pageOrder: this.pageOrder,
      pageCache: this.pageCache,
      PageScript: this.PageScript,
      productFormItem: this.productFormItem,
      handleBuyProduct: this.handleBuyProduct
    };
  },
  data() {
    return {
      authProductCodes: [],
      productFormItem: {
        // Mock 新产品需进行表单配置
        HDBC: {
          checked: false,
          coverageAmount: null,
          productFeeId: null,
          productFeeCode: null,
          form: {
            coveragePeriod: null,
            carLevel: null
          },
          config: {
            labelWidth: 120,
            fmCode: "HDBC",
            fmName: "代步补偿",
            formItems: [
              {
                component: "HSelect",
                prop: "coveragePeriod",
                label: "年份",
                show: false,
                disabled: false,
                view: true,
                col: 24,
                rules: [
                  {
                    required: true,
                    message: "请选择年份",
                    trigger: "change"
                  }
                ],
                id: "a68210d6-3760-4b07-aec9-5526a921e2e3",
                dictCode: "CS_COVERAGE_PERIOD",
                filters: "dictFormat('CS_COVERAGE_PERIOD')",
                watch: "this.setCoverageOptions('TSL_RANK', 'carLevel', 'HDBC');",
                options: [],
                val: null,
                confirm: () => this.queryProductPrice("HDBC"),
                relName: "coveragePeriodName"
              },
              {
                component: "HSelect",
                prop: "carLevel",
                label: "级别",
                show: false,
                disabled: false,
                view: true,
                col: 24,
                rules: [
                  {
                    required: true,
                    message: "请选择级别",
                    trigger: "change"
                  }
                ],
                id: "a68210d6-3760-4b07-aec9-5526a921e666",
                dictCode: "TSL_RANK",
                filters: "dictFormat('TSL_RANK')",
                watch: "this.setCoverageOptions('CS_COVERAGE_PERIOD', 'coveragePeriod', 'HDBC');",
                options: [],
                val: null,
                confirm: () => this.queryProductPrice("HDBC"),
                relName: "carLevelName"
              }
            ]
          },
          images: [
            {
              component: "HUpload",
              prop: "policyScan",
              label: "商业险保单",
              show: true,
              disabled: false,
              view: true,
              required: true,
              limit: 3,
              accept: "*",
              col: 12,
              rules: [
                {
                  required: true,
                  disabled: false,
                  message: "必填项",
                  trigger: "onBlur"
                }
              ],
              val: [],
              opts: ["camera", "image", "file"],
              busiType: "contractAttachment",
              iconImgUrl: "@/assets/images/upload/ucSaleContract.png",
              id: "722ca824-b4da-4d13-94e4-ddfe2fe3e25d",
              uploadExtraField: {
                objCode: "vehicle"
              }
            }
          ],
          otherFactors: [
            {
              component: "HSelect",
              prop: "noPubUse",
              label: "非公共用途",
              show: true,
              disabled: false,
              view: true,
              col: 24,
              rules: [
                {
                  required: true,
                  message: "请选择非公共用途",
                  trigger: "change"
                }
              ],
              id: "a68210d6-3760-4b07-aec9-5526a921e999",
              dictCode: "COMMON_IF",
              filters: "dictFormat('COMMON_IF')",
              watch: null,
              options: [],
              val: "1",
              relName: "noPubUseName"
            },
            {
              component: "HSelect",
              prop: "noDrive",
              label: "非试乘试驾",
              show: true,
              disabled: false,
              view: true,
              col: 24,
              rules: [
                {
                  required: true,
                  message: "请选择非试乘试驾",
                  trigger: "change"
                }
              ],
              id: "a68210d6-3760-4b07-aec9-5526a921e888",
              dictCode: "COMMON_IF",
              filters: "dictFormat('COMMON_IF')",
              watch: null,
              options: [],
              val: "1",
              relName: "noDriveName"
            }
          ]
        },
        FTDCPB: {
          checked: false,
          coverageAmount: null,
          productFeeId: null,
          productFeeCode: null,
          form: {
            coveragePeriod: null
          },
          config: {
            labelWidth: 120,
            fmCode: "FTDCPB",
            fmName: "换新补偿",
            formItems: [
              {
                component: "HSelect",
                prop: "coveragePeriod",
                label: "保障期限",
                show: false,
                disabled: false,
                view: true,
                col: 24,
                rules: [
                  {
                    required: true,
                    message: "请选择保障期限",
                    trigger: "change"
                  }
                ],
                id: "a68210d6-3760-4b07-aec9-5526a921e2e3",
                dictCode: "CS_COVERAGE_PERIOD",
                filters: "dictFormat('CS_COVERAGE_PERIOD')",
                options: [],
                val: null,
                confirm: () => this.queryProductPrice("FTDCPB"),
                relName: "coveragePeriodName"
              }
            ]
          },
          images: [
            {
              component: "HUpload",
              prop: "policyScan",
              label: "商业险保单",
              show: true,
              disabled: false,
              view: true,
              required: true,
              limit: 3,
              accept: "*",
              col: 12,
              rules: [
                {
                  required: true,
                  disabled: false,
                  message: "必填项",
                  trigger: "onBlur"
                }
              ],
              val: [],
              opts: ["camera", "image", "file"],
              busiType: "contractAttachment",
              iconImgUrl: "@/assets/images/upload/ucSaleContract.png",
              id: "722ca824-b4da-4d13-94e4-ddfe2fe3e25d",
              uploadExtraField: {
                objCode: "vehicle"
              }
            }
          ],
          otherFactors: []
        },
        KRESET: {
          checked: false,
          coverageAmount: null,
          productFeeId: null,
          productFeeCode: null,
          form: {
            coveragePeriod: null
          },
          config: {
            labelWidth: 120,
            fmCode: "KRESET",
            fmName: "钥匙重置",
            formItems: [
              {
                component: "HSelect",
                prop: "coveragePeriod",
                label: "保障期限",
                show: false,
                disabled: false,
                view: true,
                col: 24,
                rules: [
                  {
                    required: true,
                    message: "请选择保障期限",
                    trigger: "change"
                  }
                ],
                id: "a68210d6-3760-4b07-aec9-5526a921e2e3",
                dictCode: "CS_COVERAGE_PERIOD",
                filters: "dictFormat('CS_COVERAGE_PERIOD')",
                options: [],
                val: null,
                confirm: () => this.queryProductPrice("KRESET"),
                relName: "coveragePeriodName"
              }
            ]
          },
          images: [],
          otherFactors: []
        }
      },
      pageData: {
        affirmLoading: false,
        affirmShow: false,
        noPassShow: false,
        auditShow: false,
        passShow: false,
        submitLoading: false,
        isAgreement: false,
        articles: [],
        storageFlag: true,
        noPassMsg: null,
        interval: null
      },
      productData: null,
      fileLayout: [],
      calcData: {},
      pageOrder: {},
      pageCache: {},
      pageForm: {},
      pageLayout: [],
      pageLayoutMap: {},
      PageScript: scriptLib.call(this)
    };
  },
  computed: {
    ...mapState("dict", ["dictMap"]),
    ...mapState("user", ["info"]),
    scriptObj() {
      const pageScriptObj = new this.PageScript();
      pageScriptObj.pageForm = this.pageForm;
      pageScriptObj.pageLayout = this.pageLayout;
      pageScriptObj.pageLayoutMap = this.pageLayoutMap;
      pageScriptObj.pageContext = this;
      pageScriptObj.productData = this.productData;
      pageScriptObj.pageOrder = this.pageOrder;
      pageScriptObj.pageCache = this.pageCache;
      pageScriptObj.productFormItem = this.productFormItem;
      pageScriptObj.handleBuyProduct = this.handleBuyProduct;
      return pageScriptObj;
    }
  },
  async created() {
    await this.initData();
    this.productData = await layoutData.call(this);
    this.init();
    this.initFileLayout();
    if (this.productData && this.productData.name) {
      document.title = this.productData.name;
    }
    this.scriptObj.created && this.scriptObj.created();
    // 发起请求
    request({
      url: "/organization/authorization/product/web/current/load",
      method: "post"
    }).then(res => {
      this.authProductCodes = (res || []).filter(r => setting.configProductCode.includes(r.value)).map(r => r.value);
    });
  },
  methods: {
    amountFilter(value) {
      if (value) {
        return parseFloat(value).toFixed(2);
      }
      return "0.00";
    },
    handleBuyProduct(code) {
      this.productFormItem[code].config.formItems.map(fi => {
        fi.show = this.productFormItem[code].checked;
      });
      this.productFormItem[code].checked &&
        this.productFormItem[code].config.formItems.map(fi => {
          fi.dictCode && this.scriptObj.setCoverageOptions(fi.dictCode, fi.prop, code);
        });
      this.$nextTick(() => {
        setTimeout(() => {
          if (keys(this.productFormItem[code].form).every(fk => !!this.productFormItem[code].form[fk])) {
            this.queryProductPrice(code);
          }
        }, 200);
      });
      // 补充信息&影像合并
      this.initOther();
    },
    initOther() {
      // 补充信息合并
      this.pageLayoutMap["orderExt"] = {};
      this.pageLayout.map(layout => {
        if (layout.fmCode === "orderExt") {
          layout.formItems = [];
        }
      });
      keys(this.productFormItem).map(code => {
        if (this.productFormItem[code].checked) {
          this.pageLayout.map(layout => {
            if (layout.fmCode === "orderExt") {
              this.productFormItem[code].otherFactors && this.productFormItem[code].otherFactors.map(item => layout.formItems.push(item));
            }
          });
        }
      });
      console.log(this.pageLayout);
      // 影像合并
      let images = {
        idcardFront: {
          id: "fb08191e-dc5c-432e-bd61-5c7e5eb8bc08",
          component: "HUpload",
          prop: "idcardFront",
          label: "车主身份证人像面",
          desc: "",
          show: true,
          disabled: false,
          required: true,
          limit: 3,
          accept: "*",
          view: "pageForm.customer.customerType=='1'",
          col: 12,
          rules: [
            {
              required: true,
              disabled: false,
              message: "必填项",
              trigger: "onBlur"
            }
          ],
          val: [],
          opts: ["camera", "image", "file"],
          busiType: "contractAttachment",
          iconImgUrl: "@/assets/images/upload/idCard.png",
          uploadExtraField: {
            objCode: "customer"
          },
          seqNo: 1
        },
        idcardBack: {
          id: "fb08191e-dc5c-432e-bd61-5c7e5eb8bc08",
          component: "HUpload",
          prop: "idcardBack",
          label: "身份证国徽面",
          desc: "",
          show: true,
          disabled: false,
          required: true,
          limit: 3,
          accept: "*",
          view: "pageForm.customer.customerType=='1'",
          col: 12,
          rules: [
            {
              required: true,
              disabled: false,
              message: "必填项",
              trigger: "onBlur"
            }
          ],
          val: [],
          opts: ["camera", "image", "file"],
          busiType: "contractAttachment",
          iconImgUrl: "@/assets/images/upload/idCard.png",
          uploadExtraField: {
            objCode: "customer"
          },
          seqNo: 2
        },
        busiLicense: {
          id: "6eaf3734-2145-4e95-8a5b-fa7f13b0cb4a",
          component: "HUpload",
          prop: "busiLicense",
          label: "营业执照影像",
          show: false,
          disabled: false,
          required: true,
          view: "pageForm.customer.customerType=='2'",
          limit: 3,
          accept: "*",
          col: 12,
          rules: [
            {
              required: true,
              disabled: false,
              message: "必填项",
              trigger: "onBlur"
            }
          ],
          val: [],
          opts: ["camera", "image", "file"],
          busiType: "contractAttachment",
          iconImgUrl: "@/assets/images/upload/testReport.png",
          uploadExtraField: {
            objCode: "customer"
          },
          seqNo: 3
        },
        vehicleLicense: {
          component: "HUpload",
          prop: "vehicleLicense",
          label: "行驶证正本影像",
          desc: "",
          show: true,
          disabled: false,
          view: true,
          required: true,
          limit: 3,
          accept: "*",
          col: 12,
          rules: [
            {
              required: true,
              disabled: false,
              message: "必填项",
              trigger: "onBlur"
            }
          ],
          val: [],
          opts: ["camera", "image", "file"],
          busiType: "contractAttachment",
          iconImgUrl: "@/assets/images/upload/drivinglicense_front.png",
          id: "46f332a3-8918-4b96-854f-971ecb1a0b18",
          uploadExtraField: {
            objCode: "vehicle"
          },
          seqNo: 4
        },
        other: {
          component: "HUpload",
          prop: "other",
          label: "其他材料",
          show: true,
          disabled: false,
          view: true,
          limit: 3,
          accept: "*",
          col: 12,
          rules: [],
          val: [],
          opts: ["camera", "image", "file"],
          busiType: "contractAttachment",
          iconImgUrl: "@/assets/images/upload/testReport.png",
          id: "5465edc6-41bb-4851-870b-1e297e39f9a3",
          uploadExtraField: {
            objCode: "vehicle"
          },
          seqNo: 1000
        }
      };
      keys(this.productFormItem).map(code => {
        if (this.productFormItem[code].checked) {
          this.productFormItem[code].images &&
            this.productFormItem[code].images.map((image, ind) => {
              images[image.prop] = image;
              images[image.prop].seqNo = 5 + ind;
            });
        }
      });
      let files = [];
      keys(images).map(ik => {
        files.push(images[ik]);
      });
      this.pageLayoutMap["images"] = {};
      sortBy(files, ["seqNo"]) &&
        sortBy(files, ["seqNo"]).map(image => {
          this.pageLayoutMap["images"][image.prop] = image;
        });
      this.pageLayout.map(layout => {
        if (layout.fmCode === "images") {
          layout.formItems = sortBy(files, ["seqNo"]);
        }
      });
      this.fileLayout = sortBy(files, ["seqNo"]);
    },
    queryProductPrice(code) {
      this.$nextTick(async () => {
        if (values(this.productFormItem[code].form).every(v => !!v)) {
          let data = {
            ...this.productFormItem[code].form,
            ...this.pageForm.customer,
            ...this.pageForm.vehicle,
            ...this.pageForm.orderExt,
            ...this.pageForm.saleInfo,
            ...this.pageForm.combo,
            productCode: code
          };
          data.carLevel = this.productFormItem[code].form.carLevel;
          data.invoicePrice = data.invoicePrice ? data.invoicePrice : null;
          const res = await request({
            url: "/afis-carservice/prd/feeCfg/match/item",
            method: "post",
            data
          });
          if (res) {
            this.productFormItem[code].coverageAmount = res.retailPrice;
            this.productFormItem[code].productFeeId = res.id;
            this.productFormItem[code].productFeeCode = res.configCode;
            this.productFormItem[code].costAmount = res.orderPrice;
            this.productFormItem[code].orderPrice = res.orderPrice;
          }
          this.pageForm.saleInfo.saleAmount = 0;
          this.authProductCodes.map(ap => {
            this.pageForm.saleInfo.saleAmount += this.productFormItem[ap].coverageAmount || 0;
          });
        }
      });
    },
    dayjs,
    init() {
      this.pageData.articles.push({
        label: "服务条款",
        content: this.productData && this.productData.insuredNotice
      });
      const { id } = this.$route.query;
      this.scriptObj.serviceToPage(id);
    },
    async initData() {
      // 字典加载
      await this.$store.dispatch("dict/loadDict");
    },
    initFileLayout() {
      if (!(this.pageLayout && this.pageLayout.length > 0)) {
        return false;
      }
      const filePageLayout = this.pageLayout.filter(res => res.fmCode === "images");
      if (!(filePageLayout && filePageLayout.length > 0)) {
        return false;
      }
      filePageLayout.forEach(item => {
        if (!item.formItems) {
          return false;
        }
        this.fileLayout = this.fileLayout.concat(item.formItems);
      });
    },
    initInterval() {
      this.pageData.interval = setInterval(this.calcInterval, 1000 * 3);
    },
    calcInterval() {
      this.clearInterval();
      this.toList();
    },
    clearInterval() {
      this.pageData.interval && clearInterval(this.pageData.interval);
    },
    protocolCheck() {
      this.pageData.isAgreement = !this.pageData.isAgreement;
    },
    protocolFinish() {
      this.pageData.isAgreement = true;
    },
    protocolShow(action) {
      this.$refs.protocolRef.show(action);
    },
    toList() {
      this.$router.replace({
        path: "/product/orderMoreList"
      });
    },
    handleImagChange(code, images) {
      this.pageForm.images[code] = images;
    },
    async handleFileUpload(upLoadObj, imgItem) {
      let { file } = upLoadObj;
      upLoadObj.status = "uploading";
      upLoadObj.message = "上传中...";
      const length = this.pageForm.images[imgItem.prop] && this.pageForm.images[imgItem.prop].length;
      const isImg = file.type.indexOf("image/") > -1 ? true : false;
      let isLt5M = file.size / 1024 / 1024 < 5;
      if (isImg && !isLt5M) {
        // 超过5m的图片文件, 启用压缩
        const compressFiles = await new ImagesQuicklyCompress({
          mode: "pixel", // 根据像素总大小压缩
          num: 1e6, // 压缩后图片的总像素都是100万（相当于1000px * 1000px的图片）
          size: "500kb", // 图片大小超过500kb执行压缩
          imageType: file.type, // jpeg压缩效果十分理想
          quality: 0.8,
          orientation: false
        }).compressor([file]);
        file = new window.File([compressFiles[0]], file.name, { type: compressFiles[0].type });
      }
      let isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$toast("请上传10M以下的文件");
        this.pageForm.images[imgItem.prop].splice(length - 1, 1);
        return false;
      }
      // 通过 FormData 对象上传文件
      const formData = new FormData();
      formData.append("file", file);
      formData.append("busiType", imgItem.busiType || "productImage");
      formData.append("relateId", null);
      formData.append("category", "public");
      // 发起请求
      request({
        url: "/afis-engine/dfs/storage/upload",
        method: "post",
        data: formData,
        headers: {
          "Access-Token": store.getters.info && store.getters.info.token,
          "Content-Type": "multipart/form-data"
        }
      })
        .then(res => {
          upLoadObj.status = "done";
          upLoadObj.message = "";
          const fileObj = {
            fieldCode: imgItem.prop,
            fileId: res.fileId,
            filePath: res.fileUrl,
            fileName: file.name,
            fileType: file.type,
            fileSize: file.size,
            name: file.name,
            url: res.fileUrl,
            objCode: res.objCode,
            isImage: isImg
          };
          if (imgItem.uploadExtraField) {
            merge(fileObj, imgItem.uploadExtraField);
          }
          // this.fileForm[imgItem.prop].push(fileObj)
          this.pageForm.images[imgItem.prop].splice(length - 1, 1, fileObj);
        })
        .catch(err => {
          upLoadObj.status = "failed";
          upLoadObj.message = "上传失败";
          this.$toast(err.message || "上传失败");
          this.pageForm.images[imgItem.prop].splice(length - 1, 1);
        });
    },
    validateField(fmCode, field) {
      return new Promise(resolve => {
        for (const formItem of this.pageLayout) {
          if (formItem.fmCode === fmCode) {
            const formItemRef = this.$refs[`${formItem.fmCode}FormRef`];
            formItemRef[0]
              .validateField(field)
              .then(() => {
                resolve(true);
              })
              .catch(() => {
                resolve(false);
              });
          }
        }
      });
    },
    async verifyInfo() {
      for (const formItem of this.pageLayout) {
        if (formItem.fmCode === "images") {
          continue;
        }
        const formItemRef = this.$refs[`${formItem.fmCode}FormRef`];
        const validate = await formItemRef[0].validateForm();
        if (!validate) {
          this.$notify({ type: "danger", message: `请完善${formItem.fmName}` });
          return false;
        }
      }
      for (const fileItem of this.fileLayout) {
        if (!fileItem.show) {
          continue;
        }
        if (fileItem.required && (!this.pageForm.images[fileItem.prop] || this.pageForm.images[fileItem.prop].length < 1)) {
          this.$notify({ type: "danger", message: `请上传【${fileItem.label}】影像` });
          return false;
        }
        const images = this.pageForm.images[fileItem.prop] || [];
        for (const image of images) {
          if (image.status && image.status === "uploading") {
            this.$notify({ type: "danger", message: `【${fileItem.label}】影像上传中,请稍后提交` });
            return false;
          }
        }
      }
      return true;
    },
    async affirm() {
      const verifyFlag = await this.verifyInfo();
      if (!verifyFlag) {
        return false;
      }

      if (!this.pageData.isAgreement) {
        this.$notify({ type: "danger", message: "请阅读并勾选服务协议" });
        return false;
      }
      // 定价要素获取
      let reqData = {
        calProdData: "1"
      };
      if (this.pageForm.coverage && this.pageForm.coverage.startDate && this.pageForm.coverage.productCode === "ASCXDB") {
        reqData.startDate = this.pageForm.coverage.startDate;
      }
      this.productData.priceFactors &&
        this.productData.priceFactors.forEach(item => {
          reqData[item.h5FieldCode] = get(this.pageForm, `${item.h5ObjCode}.${item.h5FieldCode}`, null);
        });
      this.authProductCodes.map(code => {
        if (this.productFormItem[code].checked) {
          reqData.productCode = code;
          if (values(this.productFormItem[code].form).every(v => !!v)) {
            let data = {
              ...this.productFormItem[code].form,
              ...this.pageForm.customer,
              ...this.pageForm.vehicle,
              ...this.pageForm.orderExt,
              ...this.pageForm.saleInfo,
              ...this.pageForm.combo,
              productCode: code
            };
            data.carLevel = this.productFormItem[code].form.carLevel;
            reqData = Object.assign(reqData, data);
          }
        }
      });
      this.pageData.affirmLoading = true;
      reqData.invoicePrice = reqData.invoicePrice ? reqData.invoicePrice : null;
      request({
        url: "/afis-carservice/prd/feeCfg/match/item",
        method: "post",
        data: reqData
      })
        .then(res => {
          this.pageData.affirmLoading = false;
          this.calcData = res;
          this.pageData.affirmShow = true;
          // this.pageForm.coverage.productFeeId = res.id;
          // this.pageForm.coverage.productFeeCode = res.configCode;
          // this.pageForm.coverage.productFeeName = res.configName;
          this.scriptObj.feeMatch && this.scriptObj.feeMatch(res);
        })
        .catch(error => {
          this.pageData.affirmLoading = false;
        });
    },
    storage() {
      // 转换提交数据
      const submitForm = this.scriptObj.pageToService();
      submitForm.coverages = this.authProductCodes
        .filter(code => this.productFormItem[code].checked)
        .map(code => {
          let form = cloneDeep(this.productFormItem[code].form);
          form.coverageAmount = this.productFormItem[code].coverageAmount;
          form.productFeeId = this.productFormItem[code].productFeeId;
          form.productFeeCode = this.productFormItem[code].productFeeCode;
          form.productCode = code;
          form.productName = this.productFormItem[code].config.fmName;
          form.costAmount = this.productFormItem[code].orderPrice;
          form.orderPrice = this.productFormItem[code].orderPrice;
          form.isCombo = this.productFormItem[code].isCombo;
          form.comboCode = submitForm.comboCode;
          form.comboName = submitForm.comboName;
          form.checked = true;
          return form;
        });
      this.pageData.affirmLoading = true;
      request({
        url: "/afis-carservice/contract/order/temporalOrder",
        method: "post",
        data: submitForm
      })
        .then(res => {
          this.pageData.affirmLoading = false;
          if (res && res.orderId) {
            this.pageForm.id = res.orderId;
            this.$toast("暂存成功");
          } else {
            this.$toast("暂存失败");
          }
        })
        .catch(error => {
          this.pageData.affirmLoading = false;
          this.$toast("暂存失败");
        });
    },
    async submit() {
      const verifyFlag = await this.verifyInfo();
      if (!verifyFlag) {
        return false;
      }
      // 转换提交数据
      let submitForm = this.scriptObj.pageToService();
      submitForm.coverages = this.authProductCodes
        .filter(code => this.productFormItem[code].checked)
        .map(code => {
          let form = cloneDeep(this.productFormItem[code].form);
          form.coverageAmount = this.productFormItem[code].coverageAmount;
          form.productFeeId = this.productFormItem[code].productFeeId;
          form.productFeeCode = this.productFormItem[code].productFeeCode;
          form.productCode = code;
          form.productName = this.productFormItem[code].config.fmName;
          form.costAmount = this.productFormItem[code].orderPrice;
          form.orderPrice = this.productFormItem[code].orderPrice;
          form.isCombo = this.productFormItem[code].isCombo;
          form.comboCode = submitForm.comboCode;
          form.comboName = submitForm.comboName;
          form.checked = true;
          return form;
        });
      // 调用服务验证
      this.pageData.submitLoading = true;
      request({
        url: "/afis-carservice/contract/order/confirm",
        method: "post",
        data: submitForm
      })
        .then(res => {
          if (res.requestCode) {
            // 核保不通过
            // this.$notify({ type: "danger", message: res.data.ruleMsgs.toString() });
            this.pageData.affirmShow = false;
            this.pageData.noPassShow = true;
            this.pageData.noPassMsg = res.data.ruleMsgs.toString();
            return false;
          }
          // 核保通过调用下单
          return request({
            url: "/afis-carservice/contract/order/" + (submitForm.id ? "update" : "save"),
            method: "post",
            data: submitForm
          });
        })
        .then(res => {
          this.pageData.submitLoading = false;
          if (res === false) {
            return false;
          }
          if (res.orderId) {
            this.pageForm.id = res.orderId;
          }
          if (res.requestCode) {
            if (res.requestCode === 40011) {
              // 核保规则校验不通过 进入人核
              this.pageData.auditShow = true;
              this.pageData.noPassMsg = res.data.ruleMsgs.toString();
              this.initInterval();
              return false;
            }
            // 下单不通过
            this.pageData.affirmShow = false;
            this.pageData.noPassShow = true;
            this.pageData.noPassMsg = res.data.ruleMsgs.toString();
            return false;
          }
          // 自核通过
          this.pageData.passShow = true;
          this.initInterval();
          // 下单通过调用支付
          // return request({
          //   url: `/afis-carservice/order/payment/${res.orderId}`,
          //   method: "post",
          //   data: {},
          // })
        })
        // .then(res=>{
        //   this.pageData.submitLoading = false;
        //   if(res===false){
        //     return false
        //   }
        //   //支付逻辑处理
        //   if(res.type==="ONLINE"){
        //     this.$router.replace({
        //       path: '/product/pay',
        //       query: {
        //         orderId: res.orderId
        //       }
        //     })
        //     return false
        //   }
        //   this.$router.replace({
        //     path: '/product/pay/finish',
        //     query: {
        //       orderId:res.orderId,
        //       orderNo:res.orderNo,
        //       contractId:res.contractId,
        //       contractNo:res.contractNo
        //     }
        //   })
        // })
        .catch(error => {
          this.pageData.submitLoading = false;
        });
    }
  },
  beforeDestroy() {
    this.clearInterval();
  }
};
</script>
<style lang="scss" scoped>
.pd-ck-cls {
  ::v-deep .van-checkbox__label {
    position: relative;
    top: -20px;
    left: 20px;
    width: 100px;
    display: block;
    color: #323233;
  }
}
@import "@/assets/style/var.scss";
::v-deep .van-checkbox {
  display: inline-block;
}
.form {
  border-radius: $border-radius-lg;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: $white;
  position: relative;
  box-shadow: 0 0 10upx rgba(0, 0, 0, 0.1);
  margin: 10px 5px;
  .form-title {
    color: $text-color;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold-lg;
    line-height: $line-height-lg;
    padding: $padding-xs;
    .title {
      border-left: 5px solid $primary-color;
      padding: 0 8px;
    }
  }
}
.fail-reason {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: 微软雅黑;
  color: rgb(102, 102, 102);
  line-height: 30px;
  padding: 0px 20px;
  word-wrap: break-word;
  padding-bottom: 20px;
}
.submitBtn {
  height: 44px;
  .fixed {
    border-top: 1px solid $border-color;
    position: fixed;
    background: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.agreement ::v-deep {
  line-height: 14px;
  .van-checkbox__icon .van-icon {
    background-color: #fff;
    border-color: #ff5228;
  }
  .van-checkbox__icon--checked .van-icon {
    color: #fff;
    border-color: #57ccc2;
    background-color: #57ccc2;
  }
}
.affirm-content ::v-deep {
  .van-cell {
    justify-content: space-between;
  }
  .van-cell__value {
    flex: 2;
  }
}
.noPass {
  max-height: 80vh;
  overflow-y: auto;
  &_close {
    text-align: right;
    font-size: 20px;
    padding: 5px 15px;
  }
}
</style>
